import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ErrorDialogComponent } from "../dialogs/error-dialog/error-dialog.component";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private dialog: MatDialog){ }
    // displays error messages
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
              console.log('Error', error)
                let errorMessage = "Anunknownerroroccured";
                if(error.error.message) {
                    errorMessage = error.error.message;
                }
                this.dialog.open(ErrorDialogComponent, {data: {message: errorMessage}});
                return throwError(error);
            })
        );
    }
}
