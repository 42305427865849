import { PaymentTypeService } from '../../../shared/services/paymenttype/paymenttype.service';
import { LocaleService } from '../../../shared/services/locale/locale.service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogComponent } from '../../../dialogs/upload-dialog/upload-dialog.component';
import { ImageDialogComponent } from '../../../dialogs/image-dialog/image-dialog.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { PaymentType } from '../../../shared/models/paymenttype.model'
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user/user.service';
@Component({
  selector: 'app-new-paymenttype',
  templateUrl: './new-paymenttype.component.html',
  styleUrls: ['./new-paymenttype.component.css']
})
export class NewPaymentTypeComponent implements OnInit, OnDestroy {
  paymenttypeIsAuthenticated = false;
  private authStatusSub: Subscription;

  // Id for polling
  timeoutId: any;

  public id: any;
  public buttonName: any;
  public cancelButton: boolean;
  public paymenttype: PaymentType = new PaymentType;
  isAdmin: boolean = true;
  constructor(public route: ActivatedRoute, public router: Router, private authService: AuthService, private paymenttypeService: PaymentTypeService, public dialog: MatDialog, public translate: TranslateService, public localeService: LocaleService, public userService: UserService) { }

  ngOnInit() {
    this.paymenttypeIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.paymenttypeIsAuthenticated = isAuthenticated;
    });
    if (localStorage.getItem('Admin') == "investigater") {
      this.isAdmin = false;
    }
    this.id = this.route.snapshot.queryParams['Id'];
    if (this.id) {
      this.paymenttypeService.getPaymentTypeById(this.id).subscribe(data => {
        let apiPaymentTypeData = data.paymenttype[0];
        this.paymenttype = apiPaymentTypeData;
      });
      this.buttonName = "Update PaymentType";
      this.cancelButton = true;
    } else {
      this.buttonName = "Submit PaymentType";
      this.cancelButton = false;
    }
  }

  SubmitPaymentType(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.buttonName == "Submit PaymentType") {
      this.paymenttypeService.createPaymentType(this.paymenttype).subscribe(res => {
        this.router.navigate(['admin/paymenttype-list']);
      });
    } else {
      this.paymenttypeService.updatePaymentTypeName(this.id, this.paymenttype).subscribe(res => {
        this.router.navigate(['admin/paymenttype-list']);
      });
    }
  }

  cancel() {
    this.router.navigate(['admin/paymenttype-list']);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
  }

}
