import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../shared/services/application/application.service';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authStatusSub: Subscription;
  applicationsThisMonth: number = 0;
  applicationsApprovedThisMonth: number = 0;
  totalAmount: number = 0;
  // Id for polling
  timeoutId: any;
  isAdmin: boolean = true;


  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;

  public charts: any = [];
  constructor(public router: Router, private authService: AuthService, public translate: TranslateService, private applicatoonService: ApplicationService) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {

    if (localStorage.getItem('Admin') == "investigater") {
      this.isAdmin = false;
    }
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });
    if (this.userIsAuthenticated) {
      this.applicatoonService.getTotalApplicationsThisMonth(sessionStorage.getItem("userId")).subscribe(data => {
        let regions = [];
        for (let city of data.city) {
          let region = regions.find(x => x.name == city.region);
          if (region) {
            region.cities.push(city.name);
          } else {
            regions.push({ name: city.region, cities: [city.name] })
          }

        }
        for (let region of regions) {
          let months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          let approved = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          for (let application of data.application) {
            if (region.cities.find(x => x == application.city)) {
              if (application.status == "Approved") {
                var currentDate = new Date();
                var currentMonth = currentDate.getMonth();
                var d = new Date(application.createdDate);
                var m = d.getMonth();
                if (currentMonth == m) {
                  for (let payment of application.payments) {
                    this.totalAmount = this.totalAmount + payment.amount;
                  }
                  this.applicationsApprovedThisMonth = this.applicationsApprovedThisMonth + 1;
                }
                approved[m] = approved[m] + 1;
              }
              var currentDate = new Date();
              var currentMonth = currentDate.getMonth();
              var d = new Date(application.createdDate);
              var m = d.getMonth();
              if (currentMonth == m) {
                this.applicationsThisMonth = this.applicationsThisMonth + 1;
              }
              months[m] = months[m] + 1;
            }

          }
          this.charts.push({
            region: region.name,
            chartData: [{
              data: months,
              label: 'Applications',
              fill: true
            }, {
              data: approved,
              label: 'Approved',
              fill: true
            }],
            chartType: 'line',
            chartLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            chartColors: [{
              backgroundColor: 'rgba(0, 0, 255, 0.2)',
              borderColor: 'rgba(255, 0, 0, 0.5)'
            }],
            chartOptions: {
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                  }
                }]
              },
              annotation: {
                drawTime: 'beforeDatasetsDraw',
                annotations: [{
                  type: 'box',
                  id: 'a-box-1',
                  yScaleID: 'y-axis-0',
                  yMin: 0,
                  yMax: 1,
                  backgroundColor: '#4cf03b'
                }, {
                  type: 'box',
                  id: 'a-box-2',
                  yScaleID: 'y-axis-0',
                  yMin: 1,
                  yMax: 2.7,
                  backgroundColor: '#fefe32'
                }, {
                  type: 'box',
                  id: 'a-box-3',
                  yScaleID: 'y-axis-0',
                  yMin: 2.7,
                  yMax: 5,
                  backgroundColor: '#fe3232'
                }]
              }
            }
          })
        }
      });


    }

  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  navigate(params) {
    switch (params) {
      case "StartObservation": {
        this.router.navigate(['/user']);
        break;
      }
      case "ConfigurehelpTool": {
        this.router.navigate(['/admin']);
        break;
      }
      case "helpToolResults": {
        this.router.navigate(['/user-helpTool-selection']);
        break;
      }
    }
  }
  navigateTo(route) {
    this.router.navigate([route]);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
  }


}

