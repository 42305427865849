import { UserService } from '../../../shared/services/user/user.service';
import { ButtonRendererComponent } from '../../../renderer/button-renderer.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {  NewRole } from "../../../shared/enums/enum";

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authStatusSub: Subscription;
  frameworkComponents: any;
  roles = NewRole;
  // Id for polling
  timeoutId: any;

  //for Grid
  rowData: any;
  columnDefs = [];
  localeText: any;
  getRowHeight: (params: any) => any;
  constructor(public router: Router, private authService: AuthService, public userService: UserService, public translate: TranslateService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    }
    this.getRowHeight = function (params) {
      return 50;
    };
  }

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });

    this.userService.getUserListData().subscribe(data => {
      this.rowData = data.user;
    });

    this.translate.get('UserListScreen').subscribe((response: any) => {
      this.initializeColumnDef(response);
    });
    this.translate.onLangChange.subscribe((event) => {
      this.translate.get('UserListScreen').subscribe((response: any) => {
        this.initializeColumnDef(response);
      });
    });

  }

  private initializeColumnDef(response: any) {
    this.columnDefs = [
      { headerName: response.Column2, width: 111, field: 'userName', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      {
        headerName: response.Column3, width: 111, field: 'name', sortable: true, filter: true, cellStyle: { textAlign: "left" }
      }, { headerName: response.Column6, width: 100, field: 'phone', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column7, width: 100, field: 'role', sortable: true, filter: true, cellStyle: { textAlign: "left" } , cellRenderer: (data) => {
        return this.roles[Number(data.value)]
      }},
      { headerName: response.Column8, width: 80, field: 'city', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column9, width: 80, field: 'region', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      {
        headerName: response.Column4,
        width: 80,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.GridEditButton.bind(this),
          label: response.Column4,
          type:"Edit"
        }, cellStyle: { textAlign: "left" }
      }, {
        headerName: response.Column5,
        width: 80,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.GridDeleteButton.bind(this),
          label: response.Column5,
          type:"Delete"
        }, cellStyle: { textAlign: "left" }
      }
    ];
    this.localeText = { noRowsToShow: response.noRowsToShow, loadingOoo: response.loading };
  }

  GridEditButton(gridData) {
    this.router.navigate(['/addUser'], { queryParams: { Id: gridData.rowData._id } });
  }

  GridDeleteButton(gridData) {
    this.userService.deleteUserById(gridData.rowData._id).subscribe(data => {
      this.userService.getUserListData().subscribe(response => {
        this.rowData = response.user;
      });
    });
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
    this.rowData = [];
  }

  newUser() {
    this.router.navigate(['/addUser']);
  }

}
