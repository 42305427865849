import { UserService } from '../../../shared/services/user/user.service';
import { LocaleService } from '../../../shared/services/locale/locale.service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { User } from '../../../shared/models/user.model'
import { TranslateService } from '@ngx-translate/core';
import { Role , NewRole } from "../../../shared/enums/enum";
import { CityService } from 'src/app/shared/services/city/city.service';
import { RegionService } from 'src/app/shared/services/region/region.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authStatusSub: Subscription;

  // Id for polling
  timeoutId: any;

  public id: any;
  public buttonName: any;
  public cancelButton: boolean;
  public user: User = new User;
  roles = NewRole;
  cities = [];
  region = [];
  keys(): Array<string> {
    var keys = Object.keys(this.roles);
    return keys.slice(keys.length / 2);
  }
  constructor(public route: ActivatedRoute, public router: Router, private authService: AuthService, private userService: UserService, public dialog: MatDialog, public translate: TranslateService, public localeService: LocaleService, private cityService: CityService, private regionService: RegionService) { }

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });
    this.cityService.getCityListData(sessionStorage.getItem("userId")).subscribe(data => {
      this.cities = data.city;
    });
    this.regionService.getRegionListData(sessionStorage.getItem("userId")).subscribe(data => {
      this.region = data.region;
    });
    this.id = this.route.snapshot.queryParams['Id'];
    if (this.id) {
      this.userService.getUserById(this.id).subscribe(data => {
        let apiUserData = data.user[0];
        this.user = apiUserData;
        this.user.role= Number(this.user.role);
        this.user.password = "";
      });
      this.buttonName = "Update User";
      this.cancelButton = true;
    } else {
      this.buttonName = "Submit User";
      this.cancelButton = false;
    }
  }

  roleChanged() {
    if (this.user.role == this.roles[this.roles[2]] ) {
      this.user.region = null;
    } else {
      if(this.user.role == this.roles[this.roles[1]] ){
        this.user.city = null;
      }else{
        this.user.city = null;
        this.user.region = null;
      }
      
    }
  }

  SubmitUser(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.buttonName == "Submit User") {
      this.userService.createUser(this.user).subscribe(res => {
        this.router.navigate(['admin/user-list']);
      });
    } else {
      this.userService.updateUserName(this.id, this.user).subscribe(res => {
        this.router.navigate(['admin/user-list']);
      });
    }
  }

  SubmitPassword(userName: string, password: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { UserName: this.user.userName },
      disableClose: true,
      autoFocus: true,
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.userService.updateUserPassword(this.id, this.user);
        this.router.navigate(['/usersList']);
      }
    });
  }

  cancel() {
    this.router.navigate(['admin/user-list']);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
  }

}
