export class User {
    _id: string;
    userName: string;
    name: string;
    phone: number;
    role: number;
    city: string;
    region: string;
    password: string;
    email:string;
}