import { ADD, Initial } from './action-types';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD:
      return [
        ...state, action.payload
      ];
    case Initial:
      return initialState;
    default:
      return state;
  }
}
