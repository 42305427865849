import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';
import {
    MatInputModule, MatCardModule, MatButtonModule, MatToolbarModule, MatExpansionModule,
    MatDialogModule, MatTableModule, MatSelectModule, MatSortModule, MatTabsModule, MatGridListModule, MatDividerModule, MatIconModule,
    MatMenuModule, MatCheckboxModule, MatSliderModule ,MatAutocompleteModule
} from '@angular/material';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from "@angular/flex-layout";
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import localeDeExtra from '@angular/common/locales/extra/de-CH';
import { ChartsModule } from 'ng2-charts';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
registerLocaleData(localeDe, 'de-CH', localeDeExtra);
import { ErrorInterceptor } from './error-interceptor';
import { AuthInterceptor } from './auth-interceptor';
import { CommonModule } from '@angular/common';
import { AgGridModule } from "ag-grid-angular/main";
// Directives
import { HeaderComponent } from '../header/header.component';
import { NavComponent } from '../nav/nav.component';
import { DialogComponent } from '../dialogs/dialog/dialog.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/', '.json');
}

@NgModule({
    imports: [FormsModule, ReactiveFormsModule,MatInputModule,
        ChartsModule,
        MatCheckboxModule,
        MatSliderModule,
        MatAutocompleteModule,
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatExpansionModule,
        MatDialogModule,
        

        A11yModule,DragDropModule,PortalModule,
        ScrollingModule,CdkStepperModule,CdkTableModule,
        CdkTreeModule,MatBadgeModule,MatBottomSheetModule,
        MatButtonToggleModule,MatChipsModule,MatStepperModule,
        MatDatepickerModule,MatNativeDateModule,
        MatRippleModule,MatPaginatorModule,
        MatProgressBarModule,MatProgressSpinnerModule
        ,MatRadioModule,MatSidenavModule,
        MatSlideToggleModule,MatSnackBarModule,
        MatTooltipModule,MatTreeModule,

        MatTableModule,
        MatSelectModule,
        MatSortModule,
        MatTabsModule,
        MatGridListModule,
        MatDividerModule,
        FlexLayoutModule,
        CommonModule,
        AgGridModule.withComponents([]),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
    declarations: [
        NavComponent,
        HeaderComponent,
        DialogComponent,
        ButtonRendererComponent
    ],
    exports: [
        NavComponent,
        HeaderComponent,
        DialogComponent,
        ButtonRendererComponent,
        FormsModule,ReactiveFormsModule, MatInputModule,
        MatCheckboxModule,
        MatSliderModule,
        MatCardModule,
        MatAutocompleteModule,
        ChartsModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatExpansionModule,
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        MatSortModule,
        MatTabsModule,
        MatGridListModule,
        MatDividerModule,
        FlexLayoutModule,
        TranslateModule,
        AgGridModule,
        A11yModule,DragDropModule,PortalModule,
        ScrollingModule,CdkStepperModule,CdkTableModule,
        CdkTreeModule,MatBadgeModule,MatBottomSheetModule,
        MatButtonToggleModule,MatChipsModule,MatStepperModule,
        MatDatepickerModule,MatNativeDateModule,
        MatRippleModule,MatPaginatorModule,
        MatProgressBarModule,MatProgressSpinnerModule
        ,MatRadioModule,MatSidenavModule,
        MatSlideToggleModule,MatSnackBarModule,
        MatTooltipModule,MatTreeModule
    ],
    entryComponents: [DialogComponent, ButtonRendererComponent]
})
export class SharedModule { }
