import { NgModule } from '@angular/core';
import { AdminRoutingModule } from './admin-routing.module';
import { CommonModule } from '@angular/common';
//admin modules 
import {
  NewUserComponent, CityListComponent, NewCityComponent, UserListComponent, NewApplicationComponent,
  ApplicationListComponent, NewRegionComponent, RegionListComponent, NewApplicationlogComponent,
  ApplicationlogListComponent, NewPaymentTypeComponent, PaymentTypeListComponent,ReportingListComponent
} from '../../../src/app/admin/index';
//modules
import { SharedModule } from '../shared/shared.module';
@NgModule({
  declarations: [ReportingListComponent,NewPaymentTypeComponent, PaymentTypeListComponent, NewRegionComponent, CityListComponent, NewCityComponent, RegionListComponent, NewUserComponent, UserListComponent, NewApplicationComponent, ApplicationListComponent, NewApplicationlogComponent, ApplicationlogListComponent],
  imports: [
    AdminRoutingModule,
    SharedModule,
    CommonModule
  ],
  exports: [SharedModule]
})
export class AdminModule { }