import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./services/auth/auth.service";
import { LoaderService } from './services/loader/loaderservice.service'
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//     constructor(private authService: AuthService){}
//     // adds user token to requests
//     intercept(req: HttpRequest<any>, next: HttpHandler) {
//         const authToken = this.authService.getToken();
//         const authRequest = req.clone({
//             headers: req.headers.set("Authorization", "Bearer " + authToken)
//         });
//         return next.handle(authRequest);
//     }
// }

export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, public loaderService: LoaderService) { }
  // adds user token to requests
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.loaderService.show();
      const authToken = this.authService.getToken();
      const authRequest = req.clone({
          headers: req.headers.set("Authorization", "Bearer " + authToken)
      });
      return next.handle(authRequest).pipe(
          finalize(() => {
              this.loaderService.hide();
          })
      );
  }
}
