import { ApplicationlogService } from '../../../shared/services/applicationlog/applicationlog.service';
import { ButtonRendererComponent } from '../../../renderer/button-renderer.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-applicationlog-list',
  templateUrl: './applicationlog-list.component.html',
  styleUrls: ['./applicationlog-list.component.css']
})
export class ApplicationlogListComponent implements OnInit, OnDestroy {
  applicationlogIsAuthenticated = false;
  private authStatusSub: Subscription;
  frameworkComponents: any;
  // Id for polling
  timeoutId: any;

  //for Grid
  rowData: any;
  columnDefs = [];
  localeText: any;

  constructor(public router: Router, private authService: AuthService, public applicationlogService: ApplicationlogService, public translate: TranslateService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    }
  }

  ngOnInit() {
    this.applicationlogIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.applicationlogIsAuthenticated = isAuthenticated;
    });
    this.applicationlogService.getApplicationlogListData().subscribe(data => {
      this.rowData = data.applicationlog;
    });
    this.translate.get('ApplicationlogListScreen').subscribe((response: any) => {
      this.initializeColumnDef(response);
    });
    this.translate.onLangChange.subscribe((event) => {
      this.translate.get('ApplicationlogListScreen').subscribe((response: any) => {
        this.initializeColumnDef(response);
      });
    });
  }

  initializeColumnDef(response: any) {
    this.columnDefs = [
      { headerName: response.Column1, width: 100, valueGetter: 'node.rowIndex', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column2, width: 100, field: 'applicationId', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column3, width: 100, field: 'createdDate', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column6, width: 100, field: 'userId', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column7, width: 100, field: 'status', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column8, width: 100, field: 'remarks', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      {
        headerName: response.Column4,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.GridEditButton.bind(this),
          label: response.Column4
        }, cellStyle: { textAlign: "left" }
      }, {
        headerName: response.Column5,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.GridDeleteButton.bind(this),
          label: response.Column5
        }, cellStyle: { textAlign: "left" }
      }
    ];
    this.localeText = { noRowsToShow: response.noRowsToShow, loadingOoo: response.loading };
  }

  GridEditButton(gridData) {
    this.router.navigate(['/addApplicationlog'], { queryParams: { Id: gridData.rowData._id } });
  }

  GridDeleteButton(gridData) {
    this.applicationlogService.deleteApplicationlogById(gridData.rowData._id).subscribe(data => {
      this.applicationlogService.getApplicationlogListData().subscribe(response => {
        this.rowData = response.applicationlog;
      });
    });
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
    this.rowData = [];
  }

}
