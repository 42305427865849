export class Application {
    _id: string;
    cnic: string;
    name: string;
    fName: string;
    city: string;
    region:string;
    phone: number;
    status: number;
    createdDate: Date;
    adminId: string;
    adminName: string;
    userId: string;
    userName: string;
    assignedTo: string;
    assignedToName: string;
    remarks: string;
    images: any = [];
    payments: any = [];
    category: string;
    amount: number;
    url : string;
}