import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Authdata } from "../../models/auth-data.model";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';

const BACKEND_URL = environment.apiUrl + "/applicationlog";

@Injectable({
  providedIn: 'root'
})
export class ApplicationlogService {

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  createApplicationlog(applicationlog) {
    return this.http.post(BACKEND_URL + "/createApplicationlog", applicationlog)
  }

  updateApplicationlogName(id: string, obj: object) {
    return this.http.put(BACKEND_URL + "/updateApplicationlogById/" + id, obj)
  }

  getApplicationlogListData() {
    return this.http.get<any>(BACKEND_URL + "/applicationlogList");
  }

  getApplicationlogById(id: string) {
    return this.http.get<any>(BACKEND_URL + "/applicationlogById/" + id);
  }

  deleteApplicationlogById(id: string) {
    return this.http.delete<any>(BACKEND_URL + "/deleteApplicationlogById/" + id);
  }
}
