import { ApplicationService } from '../../../shared/services/application/application.service';
import { ButtonRendererComponent } from '../../../renderer/button-renderer.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StatusType, City, NewRole } from "../../../shared/enums/enum";
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { RegionService } from 'src/app/shared/services/region/region.service';
import { CityService } from 'src/app/shared/services/city/city.service';

@Component({
  selector: 'app-reporting-list',
  templateUrl: './reporting-list.component.html',
  styleUrls: ['./reporting-list.component.css']
})
export class ReportingListComponent implements OnInit, OnDestroy {
  applicationIsAuthenticated = false;
  private authStatusSub: Subscription;
  public status: StatusType = new StatusType;
  public city: City = new City
  frameworkComponents: any;
  // Id for polling
  timeoutId: any;

  //for Grid
  rowData: any;
  columnDefs = [];
  localeText: any;
  getRowHeight: (params: any) => any;
  filters;
  region: any = [];
  selectedRegion;
  applicationsApprovedThisMonth: number = 0;
  applicationsThisMonth: number = 0;
  totalAmount: any = 0;
  gridApi: any;
  showRegion: boolean =true;
  constructor(public router: Router, private cityService: CityService, private authService: AuthService, public applicationService: ApplicationService, public translate: TranslateService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    }
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.filters = { status: "", region: "", from: d, to: new Date };
    this.selectedRegion = null;
    this.getRowHeight = function (params) {
      return 50;
    };
  }

  ngOnInit() {
    this.applicationIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.applicationIsAuthenticated = isAuthenticated;
    });
    this.translate.get('ApplicationListScreen').subscribe((response: any) => {
      this.initializeColumnDef(response);
    });
    this.translate.onLangChange.subscribe((event) => {
      this.translate.get('ApplicationListScreen').subscribe((response: any) => {
        this.initializeColumnDef(response);
      });
    });
    this.cityService.getCityListData(sessionStorage.getItem("userId")).subscribe(data => {
      let regions = [];
      for (let city of data.city) {
        let region = regions.find(x => x.name == city.region);
        if (region) {
          region.cities.push(city.name);
        } else {
          regions.push({ name: city.region, cities: [city.name] })
        }

      }
      if(localStorage.getItem('Admin') == JSON.stringify(NewRole[NewRole[1]])){
        this.showRegion= false;
        this.selectedRegion = regions[0];
      }
      this.region = regions;
      this.applyFilters();
    });
  }

  initializeColumnDef(response: any) {
    this.columnDefs = [
      { headerName: response.Column2, width: 100, field: 'cnic', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column3, width: 100, field: 'name', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column12, width: 120, field: 'fName', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column7, width: 80, field: 'city', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column9, width: 80, field: 'status', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column14, width: 100, field: 'Amount', sortable: true, filter: true, cellStyle: { textAlign: "left" }, }
    ];
    this.localeText = { noRowsToShow: response.noRowsToShow, loadingOoo: response.loading };
  }

  clearFilters() {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.filters = { status: "", region: "", from: d, to: new Date() };
    if(this.showRegion)
    this.selectedRegion = null;
    this.applyFilters();

  }
  onGridReady(event) {
    this.gridApi = event.api;
  }

  exportToExcel(): void {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'report',
      columnSeparator: document.querySelector("#columnSeparator")
    };
    this.gridApi.exportDataAsCsv(params);
  }

  applyFilters() {
    this.applicationService.getReportingByFilter(this.filters, sessionStorage.getItem("userId")).subscribe((response: any) => {
      let temp = [];
      this.totalAmount = 0;
      this.applicationsThisMonth = 0;
      for (let application of response['application']) {
        let rowTotal = 0;
        if (this.selectedRegion && this.selectedRegion.cities.find(x => x == application.city)) {
          for (let payment of application.payments) {
            if (payment.active) {
              rowTotal = rowTotal + payment.amount;
              this.totalAmount = this.totalAmount + payment.amount;
            }
          }
          this.applicationsThisMonth = this.applicationsThisMonth + 1;
          temp.push({ ...application, "Amount": rowTotal });
        }
        if (!this.selectedRegion) {
          for (let payment of application.payments) {
            if (payment.active) {
              rowTotal = rowTotal + payment.amount;
              this.totalAmount = this.totalAmount + payment.amount;
            }
          }
          this.applicationsThisMonth = this.applicationsThisMonth + 1;
          temp.push({ ...application, "Amount": rowTotal });
        }
        this.rowData = temp;
      }
    });

  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
    this.rowData = [];
  }

  newApplication() {
    this.router.navigate(['/addApplication']);
  }

}
