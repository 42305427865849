import { ApplicationService } from '../../../shared/services/application/application.service';
import { ButtonRendererComponent } from '../../../renderer/button-renderer.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StatusType, City, NewStatus } from "../../../shared/enums/enum";
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CityService } from 'src/app/shared/services/city/city.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.css']
})
export class ApplicationListComponent implements OnInit, OnDestroy {
  applicationIsAuthenticated = false;
  private authStatusSub: Subscription;
  public city: City = new City;
  frameworkComponents: any;
  // Id for polling
  timeoutId: any;
  public status = NewStatus;
  //for Grid
  rowData: any;
  columnDefs = [];
  localeText: any;
  getRowHeight: (params: any) => any;
  filters;
  cities: any=[];
  keys(): Array<string> {
    var keys = Object.keys(this.status);
    return keys.slice(keys.length / 2);
  }
  constructor(public router: Router, private authService: AuthService, public applicationService: ApplicationService, public translate: TranslateService,public cityService: CityService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    }
    this.filters = { name: "", fName: "", cnic: "", status: "" , city: "" ,phone: ""};
    this.getRowHeight = function (params) {
      return 50;
    };
  }

  ngOnInit() {
    this.cityService.getCityListData(sessionStorage.getItem("userId")).subscribe(data => {
      this.cities = data.city;
    });
    this.applicationIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.applicationIsAuthenticated = isAuthenticated;
    });
    this.applicationService.getApplicationListData(sessionStorage.getItem("userId")).subscribe(data => {
      this.rowData = data.application;
    });
    this.translate.get('ApplicationListScreen').subscribe((response: any) => {
      this.initializeColumnDef(response);
    });
    this.translate.onLangChange.subscribe((event) => {
      this.translate.get('ApplicationListScreen').subscribe((response: any) => {
        this.initializeColumnDef(response);
      });
    });

  }

  initializeColumnDef(response: any) {
    this.columnDefs = [
      { headerName: response.Column2, width: 100, field: 'cnic', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column3, width: 100, field: 'name', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column12, width: 120, field: 'fName', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column7, width: 80, field: 'city', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column9, width: 80, field: 'status', sortable: true, filter: true, cellStyle: { textAlign: "left" } , cellRenderer: (data) => {
        return this.status[Number(data.value)]
      }},
      {
        headerName: response.Column4,
        cellRenderer: 'buttonRenderer',
        width: 80,
        cellRendererParams: {
          onClick: this.GridEditButton.bind(this),
          label: response.Column4,
          type:"Edit"
        }, cellStyle: { textAlign: "left" },
      }, {
        headerName: response.Column13,
        cellRenderer: 'buttonRenderer',
        width: 80,
        cellRendererParams: {
          onClick: this.GridViewButton.bind(this),
          label: response.Column13,
          type:"View"
        }, cellStyle: { textAlign: "left" }
      },
      {
        headerName: response.Column5,
        width: 80,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.GridDeleteButton.bind(this),
          label: response.Column5,
          type:"Delete"
        }, cellStyle: { textAlign: "left" }
      }
    ];
    this.localeText = { noRowsToShow: response.noRowsToShow, loadingOoo: response.loading };
  }

  GridEditButton(gridData) {
    this.router.navigate(['/addApplication'], { queryParams: { Id: gridData.rowData._id } });
  }

  GridViewButton(gridData) {
    this.router.navigate(['/addApplication'], { queryParams: { Id: gridData.rowData._id, readonly: true } });
  }

  clearFilters(){
    this.filters = { name: "", fName: "", cnic: "", status: null , city: "" , phone:""}
    this.applicationService.getApplicationByFilter(this.filters,sessionStorage.getItem("userId")).subscribe((response: any) => {
      this.rowData = response['application'];
    });

  }
  applyFilters() {
    if(this.filters.status)
    this.filters.status=JSON.stringify(this.filters.status);
    this.applicationService.getApplicationByFilter(this.filters,sessionStorage.getItem("userId")).subscribe((response: any) => {
      this.rowData = response['application'];
    });
    if(this.filters.status)
    this.filters.status=Number(this.filters.status);
  }

  GridDeleteButton(gridData) {
    this.applicationService.deleteApplicationById(gridData.rowData._id).subscribe(data => {
      this.applicationService.getApplicationListData(sessionStorage.getItem("userId")).subscribe(response => {
        this.rowData = response.application;
      });
    });
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
    this.rowData = [];
  }

  newApplication() {
    this.router.navigate(['/addApplication']);
  }

}
