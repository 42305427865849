import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-finish-dialog',
  templateUrl: './finish-dialog.component.html',
  styleUrls: ['./finish-dialog.component.css']
})
export class FinishDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FinishDialogComponent>, public router: Router) {
  }

  ngOnInit() {
  }

  Evaluate() {
    let helpToolCode = JSON.parse(sessionStorage.getItem("helpToolCode"));
    let role = JSON.parse(sessionStorage.getItem("Role"));
    this.router.navigate(['sample-evaluation'], { queryParams: { helpToolCode: helpToolCode, Role: role} });
  }
}
