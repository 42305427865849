// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-button-renderer',
  template: `<button *ngIf="type =='Edit'" class="crud-btn" type="button" (click)="onClick($event)"><span class="fa fa-edit"></span></button>
  <button *ngIf="type =='View'" class="crud-btn" type="button" (click)="onClick($event)"><span class="fa fa-binoculars"></span></button>
  <button *ngIf="type =='Delete'" class="crud-btn" type="button" (click)="onClick($event)"><span class="fa fa-trash"></span></button>
  `,
  // template: `<button class="crud-btn" type="button" (click)="onClick($event)"><span class="fa fa-binoculars"></span></button>`,
  // template: `<button class="crud-btn" type="button" (click)="onClick($event)"><span class="fa fa-trash"></span></button>`,
  // template: `<button type="button" mat-raised-button (click)="onClick($event)">{{label}}</button>`,
  styleUrls: ['./button-renderer.component.css']
})
export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;
  type: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    this.type = this.params.type || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
}
