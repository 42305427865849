import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Authdata } from "../../models/auth-data.model";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';

const BACKEND_URL = environment.apiUrl + "/application";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  createApplication(application) {
    return this.http.post(BACKEND_URL + "/createApplication", application)
  }

  updateApplicationName(id: string, obj: object) {
    return this.http.put(BACKEND_URL + "/updateApplicationById/" + id, obj)
  }
  getApplicationByFilter(obj: object, id: string) {
    return this.http.put(BACKEND_URL + "/getApplicationByFilter/"+ id, obj)
  }
  getReportingByFilter(obj: object, id: string) {
    return this.http.put(BACKEND_URL + "/getReportingByFilter/"+ id, obj)
  }
  getApplicationListData(id) {
    return this.http.get<any>(BACKEND_URL + "/applicationList/" + id);
  }

  getImageById(id: string) {
    return this.http.get<any>(BACKEND_URL + "/getImageById/" + id);
  }

  getApplicationById(id: string) {
    return this.http.get<any>(BACKEND_URL + "/applicationById/" + id);
  }

  deleteApplicationById(id: string) {
    return this.http.delete<any>(BACKEND_URL + "/deleteApplicationById/" + id);
  }

  getTotalApplicationsThisMonth(id){
    return this.http.get<any>(BACKEND_URL + "/getTotalApplicationsThisMonth/" + id);
  }
  getApplicationsApprovedThisMonth(){
    return this.http.get<any>(BACKEND_URL + "/getApplicationsApprovedThisMonth");
  }
}
