import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Authdata } from "../../models/auth-data.model";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';

const BACKEND_URL = environment.apiUrl + "/user";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private isAuthenticated = false;
  private token: string;
  private tokenTimer: any;
  private userId: string;
  private userName: string;
  private authStatusListener = new Subject<boolean>()

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getUserName() {
    return this.userName;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }



  createUser(userName: string, password: string) {
    const authData: Authdata = { userName: userName, password: password }
    this.http.post<{ message: string, userId: string }>(BACKEND_URL + "/signup", authData)
      .subscribe(response => {
        console.log(response)
        if (response.userId) {
          this.openDialog();
        }
      })
  }

  getUser(userName: string) {
    return this.http.get<{ user: string }>(BACKEND_URL + "/" + userName)
  }

  // check user credential and store them into local storage
  login(userName: string, password: string) {
    const authData: Authdata = { userName: userName, password: password }
    this.http.post<{ token: string, expiresIn: number, userId: string, userName: string ,role :string }>(BACKEND_URL + "/login", authData)
      .subscribe(response => {
        const token = response.token;
        this.token = token;
        if (token) {
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true;
          this.userId = response.userId;
          this.userName = response.userName;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          console.log(expirationDate)
          this.saveAuthData(token, expirationDate, this.userId, this.userName)
            localStorage.setItem('Admin', response.role);
            this.router.navigate([''])
        }
      })
  }
  // get user data from local storage after page reload
  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.userName = authInformation.userName;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }
  // clear user data from local storage
  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    this.userId = null;
    this.userName = null;
    localStorage.clear();
    this.clearAuthData();
    this.router.navigate(['/']);
  }
  // start timer for user token
  private setAuthTimer(duration: number) {
    console.log("Setting timer: " + duration);
    this.tokenTimer = setTimeout(() => { this.logout() }, duration * 1000);
  }
  // save user date into local storage
  private saveAuthData(token: string, expirationDate: Date, userId: string, userName: string) {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("expiration", expirationDate.toISOString());
    sessionStorage.setItem("userId", userId);
    sessionStorage.setItem("userName", userName);
  }
  // delete user data
  private clearAuthData() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("expiration");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");
  }
  // fetch user data
  private getAuthData() {
    const token = sessionStorage.getItem("token");
    const expirationDate = sessionStorage.getItem("expiration");
    const userId = sessionStorage.getItem("userId");
    const userName = sessionStorage.getItem("userName");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      userName: userName
    }
  }
}
