import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "../shared/services/auth/auth.guard";
//admin modules 
import {
  NewUserComponent, CityListComponent, NewCityComponent, UserListComponent, NewApplicationComponent,
  ApplicationListComponent, NewRegionComponent, RegionListComponent, NewApplicationlogComponent,
  ApplicationlogListComponent, NewPaymentTypeComponent, PaymentTypeListComponent,ReportingListComponent
} from '../../../src/app/admin/index';

const routes: Routes = [
  { path: 'addUser', component: NewUserComponent },
  { path: 'user-list', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'addApplication', component: NewApplicationComponent, canActivate: [AuthGuard] },
  { path: 'application-list', component: ApplicationListComponent, canActivate: [AuthGuard] },
  { path: 'reporting-list', component: ReportingListComponent, canActivate: [AuthGuard] },
  { path: 'addRegion', component: NewRegionComponent, canActivate: [AuthGuard] },
  { path: 'region-list', component: RegionListComponent, canActivate: [AuthGuard] },
  { path: 'addPaymentType', component: NewPaymentTypeComponent, canActivate: [AuthGuard] },
  { path: 'paymenttype-list', component: PaymentTypeListComponent, canActivate: [AuthGuard] },
  { path: 'addCity', component: NewCityComponent, canActivate: [AuthGuard] },
  { path: 'city-list', component: CityListComponent, canActivate: [AuthGuard] },
  { path: 'addApplicationlog', component: NewApplicationlogComponent, canActivate: [AuthGuard] },
  { path: 'applicationlog-list', component: ApplicationlogListComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule], providers: [AuthGuard]
})
export class AdminRoutingModule { }