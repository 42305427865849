import { ApplicationService } from '../../../shared/services/application/application.service';
import { LocaleService } from '../../../shared/services/locale/locale.service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogComponent } from '../../../dialogs/upload-dialog/upload-dialog.component';
import { ImageDialogComponent } from '../../../dialogs/image-dialog/image-dialog.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { Application } from '../../../shared/models/application.model'
import { TranslateService } from '@ngx-translate/core';
import { StatusType, City, Image, Category, NewStatus ,NewRole} from "../../../shared/enums/enum";
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user/user.service';
import { CityService } from 'src/app/shared/services/city/city.service';
import { PaymentTypeService } from 'src/app/shared/services/paymenttype/paymenttype.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
@Component({
  selector: 'app-new-application',
  templateUrl: './new-application.component.html',
  styleUrls: ['./new-application.component.css']
})
export class NewApplicationComponent implements OnInit, OnDestroy {
  applicationIsAuthenticated = false;
  private authStatusSub: Subscription;

  // Id for polling
  timeoutId: any;

  public id: any;
  public buttonName: any;
  public cancelButton: boolean;
  public application: Application = new Application;
  public status = NewStatus;
  public category: Category = new Category;
  public users: User[];
  payments = [];
  images = [];
  imageLinks = [];
  cities = [];
  applog = [];
  readonly: boolean = false;
  isAdmin: boolean = true;
  columnDefs = [];
  frameworkComponents: { buttonRenderer: any; };
  localeText: { noRowsToShow: any; loadingOoo: any; };
  getRowHeight: (params: any) => any;
  keys(): Array<string> {
    var keys = Object.keys(this.status);
    return keys.slice(keys.length / 2);
  }
  constructor(public route: ActivatedRoute, public router: Router, private authService: AuthService,
    private applicationService: ApplicationService, public dialog: MatDialog, public translate: TranslateService,
    public localeService: LocaleService, public userService: UserService, public cityService: CityService,
    private paymentTypeService: PaymentTypeService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    }
    this.getRowHeight = function (params) {
      let height = 0;
      height = params.data.payments.filter(x => x.active == true).length * 17;
      if (params.data.remarks.length > height) {
        height = 100 + params.data.remarks.length;
      } else {
        height = 100 + height;
      }
      return height;
    };
    this.status[this.status[0]];
  }

  ngOnInit() {
    this.applicationIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.applicationIsAuthenticated = isAuthenticated;
    });
    if (localStorage.getItem('Admin') == JSON.stringify(NewRole[NewRole[2]]) ) {
      this.isAdmin = false;
    }

    this.cityService.getCityListData(sessionStorage.getItem("userId")).subscribe(data => {
      this.cities = data.city;
    });
    this.id = this.route.snapshot.queryParams['Id'];
    this.readonly = this.route.snapshot.queryParams['readonly'];
    if (this.id) {
      this.applicationService.getApplicationById(this.id).subscribe(data => {
        let apiApplicationData = data.application[0];
        console.log('Application Data', apiApplicationData)
        // apiApplicationData.images = [];
        this.application = apiApplicationData;
        this.application.status = Number(this.application.status);
        this.paymentTypeService.getPaymentTypeListData(sessionStorage.getItem("userId")).subscribe(data => {
          for (let payment of data.paymenttype) {
            let temp = this.application.payments.find(x => x._id == payment._id);
            if (!temp) {
              payment.active = false;
              this.application.payments.push(payment);
            }
          }
        });
        this.application.url = window.location.href;
        this.application.userId = sessionStorage.getItem("userId");
        this.application.userName = sessionStorage.getItem("userName");
        this.cityChanged();
        if (data.applicationlog) {
          this.applog = data.applicationlog;
        }
        if (data.image && data.image.length > 0) {
          this.imageLinks = [{ type: "Application", ids: [] }, { type: "Record", ids: [] }, { type: "Investigation", ids: [] }, { type: "Review", ids: [] }];
          for (let image of data.image) {
            switch (image.imgType) {
              case this.imageLinks[0].type:
                this.imageLinks[0].ids.push(image.img);
                break;

              case this.imageLinks[1].type:
                this.imageLinks[1].ids.push(image.img);
                break;

              case this.imageLinks[2].type:
                this.imageLinks[2].ids.push(image.img);
                break;

              case this.imageLinks[3].type:
                this.imageLinks[3].ids.push(image.img);
                break;

            }
            // this.images.push("data:image/png;base64," + btoa([].reduce.call(new Uint8Array(image.img.data), function (p, c) { return p + String.fromCharCode(c) }, '')))
          }
        }
        if (!apiApplicationData.images) {
          this.application.images = [];
        }
        if (!this.isAdmin) {
          this.imageLinks = this.imageLinks.filter(x => x.type == "Investigation" && x.ids.length > 0);
        }
      });
      this.buttonName = "Update Application";
      this.cancelButton = true;
    } else {
      this.application.userId = sessionStorage.getItem("userId");
      this.application.userName = sessionStorage.getItem("userName");
      this.application.url = window.location.href;
      this.application.adminId = sessionStorage.getItem("userId");
      this.application.adminName = sessionStorage.getItem("userName");
      this.application.status = this.status.New;
      this.buttonName = "Submit Application";
      this.cancelButton = false;
    }
    this.translate.get('ApplicationlogListScreen').subscribe((response: any) => {
      this.initializeColumnDef(response);
    });
    this.translate.onLangChange.subscribe((event) => {
      this.translate.get('ApplicationlogListScreen').subscribe((response: any) => {
        this.initializeColumnDef(response);
      });
    });
  }
  removeImage(img) {
    let index = this.application.images.findIndex(x => x.id == img.id);
    this.application.images.splice(index, 1);
  }

  cityChanged() {
    let city =this.cities.find(x=>x.name ==this.application.city);
    if(city)
    this.application.region =city.region;
    this.userService.getUserByCityData(this.application.city).subscribe(data => {
      this.users = data.user;
    });
  }

  statusChanged() {
    return new Promise((resolve, reject) => {
      if (this.application.status == this.status[this.status[2]] || this.application.status == this.status[this.status[5]]) {
        let city = this.cities.find(x => x.name == this.application.city);
        if (city) {
          this.userService.getUserByRegionData(city.region).subscribe(data => {
            let user = data.user[0];
            this.application.assignedTo = user._id;
            this.application.assignedToName = user.name;
            resolve({ res: true })
          });
        }
      } else {
        resolve({ res: false })
      }
    });
  }

  categoryChanged() {
    if (this.application.category == this.category.type[1]) {
      this.application.amount = 50;
    } else if (this.application.category == this.category.type[2]) {
      this.application.amount = 100;
    }
  }

  assignedToChanged() {
    let user = this.users.find(x => x._id == this.application.assignedTo);
    this.application.assignedToName = user.name;
  }

  openImage(id) {
    let dialogRef;
    dialogRef = this.dialog.open(ImageDialogComponent, {
      data: { id: id },
      autoFocus: true
    });
  }

  uploadImage() {
    let status;
    if (this.isAdmin) {
      status = "Application";
    } else {
      status = "Investigation";
    }
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      data: { status: status },
      disableClose: true,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        if (this.application.images.length == 0) {
          let index = 0;
          for (let item of result) {
            this.application.images.push({ id: index, img: item.img, type: item.type });
            index++;
          }
        } else {
          let id = 0;
          for (let item of result) {
            for (let item of this.application.images) {
              if (item.id >= id) {
                id = item.id + 1;
              }
            }
            this.application.images.push({ id: id, img: item.img, type: item.type });
          }
        }
      }
    });
  }

  SubmitApplication(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (!this.isAdmin) {
      if (this.application.status != this.status[this.status[2]] && this.application.status != this.status[this.status[5]]) {
        this.application.status = this.status[this.status[2]];
      } else {
        this.application.status = this.status[this.status[5]];
      }
      this.application.assignedToName = this.application.adminName;
      this.application.assignedTo = this.application.adminId;
    }
    this.statusChanged().then(res => {
      if (this.buttonName == "Submit Application") {
        this.applicationService.createApplication(this.application).subscribe(res => {
          this.router.navigate(['admin/application-list']);
        });
      } else {
        this.applicationService.updateApplicationName(this.id, this.application).subscribe(res => {
          this.router.navigate(['admin/application-list']);
        });
      }
    });
  }

  initializeColumnDef(response: any) {
    this.columnDefs = [
      { headerName: response.Column9, width: 120, field: 'assignedTo', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      {
        headerName: response.Column3, width: 120, field: 'createdDate', sortable: true, filter: true, cellStyle: { textAlign: "left" }, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: response.Column10, width: 120, field: 'userName', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      {
        headerName: response.Column7, width: 120, field: 'status', sortable: true, filter: true, cellStyle: {
          textAlign: "left",
          overflow: "visible",
          'white-space': "pre-wrap",
          'line-height': "25px"
        }, cellRenderer: (data) => {
          return this.status[Number(data.value)]
        }
      },
      {
        headerName: response.Column8, width: 120, field: 'remarks', sortable: true, filter: true, cellStyle: {
          textAlign: "left",
          overflow: "visible",
          'white-space': "pre-wrap",
          'line-height': "25px"
        }
      },
      {
        headerName: 'Payments',
        field: 'payments', cellRenderer: (data) => {
          let temp = "";
          for (let payment of data.value) {
            if (payment.active) {
              temp = temp + `<li>
          Type: ${payment.name} &nbsp; Amount: ${payment.amount}
        </li>`
            }
          }
          return `<ul> ${temp}</ul>`;
        }
      }
    ];
    this.localeText = { noRowsToShow: response.noRowsToShow, loadingOoo: response.loading };
  }

  cancel() {
    this.router.navigate(['admin/application-list']);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
  }

}
