import { CityService } from '../../../shared/services/city/city.service';
import { ButtonRendererComponent } from '../../../renderer/button-renderer.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.css']
})
export class CityListComponent implements OnInit, OnDestroy {
  cityIsAuthenticated = false;
  private authStatusSub: Subscription;
  frameworkComponents: any;
  // Id for polling
  timeoutId: any;

  //for Grid
  rowData: any;
  columnDefs = [];
  localeText: any;

  filters;
  getRowHeight: (params: any) => number;
  constructor(public router: Router, private authService: AuthService, public cityService: CityService, public translate: TranslateService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    }
    this.filters = { name: "", fName: "", cnic: "", status: "", city: "" }
    this.getRowHeight = function (params) {
      return 50;
    };
  }

  ngOnInit() {
    this.cityIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.cityIsAuthenticated = isAuthenticated;
    });
    this.cityService.getCityListData(sessionStorage.getItem("userId")).subscribe(data => {
      this.rowData = data.city;
    });
    this.translate.get('CityListScreen').subscribe((response: any) => {
      this.initializeColumnDef(response);
    });
    this.translate.onLangChange.subscribe((event) => {
      this.translate.get('CityListScreen').subscribe((response: any) => {
        this.initializeColumnDef(response);
      });
    });

  }

  initializeColumnDef(response: any) {
    this.columnDefs = [
      { headerName: response.Column3,  field: 'name', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      { headerName: response.Column2,  field: 'region', sortable: true, filter: true, cellStyle: { textAlign: "left" } },
      {
        headerName: response.Column4,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.GridEditButton.bind(this),
          label: response.Column4,
          type:"Edit"
        }, cellStyle: { textAlign: "left" }
      },
      {
        headerName: response.Column5,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.GridDeleteButton.bind(this),
          label: response.Column5,
          type:"Delete"
        }, cellStyle: { textAlign: "left" }
      }
    ];
    this.localeText = { noRowsToShow: response.noRowsToShow, loadingOoo: response.loading };
  }

  GridEditButton(gridData) {
    this.router.navigate(['/addCity'], { queryParams: { Id: gridData.rowData._id } });
  }

  GridDeleteButton(gridData) {
    this.cityService.deleteCityById(gridData.rowData._id).subscribe(data => {
      this.cityService.getCityListData(sessionStorage.getItem("userId")).subscribe(response => {
        this.rowData = response.city;
      });
    });
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
    this.rowData = [];
  }

  newCity() {
    this.router.navigate(['/addCity']);
  }

}
