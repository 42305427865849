import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';
const BACKEND_URL = environment.apiUrl + "/locale";

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  createLocale(obj) {
    this.http.post(BACKEND_URL + "/addLocale", obj).subscribe(response => {
    });
  }

  deleteLocaleByKey(key) {
    return this.http.delete<any>(BACKEND_URL + "/deleteLocaleByKey/" + key);

  }
  getroleLocales() {
    return this.http.get<any>(BACKEND_URL + "/roleLocale");
  }

  getLocaleByKey(key: string) {
    return this.http.get<any>(BACKEND_URL + "/getLocaleByKey/" + key);
  }

  updateLocale(id: string, obj: object) {
    this.http.put(BACKEND_URL + "/updateLocaleById/" + id, obj).subscribe(response => {
    });
  }
}
