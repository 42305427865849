import { ApplicationlogService } from '../../../shared/services/applicationlog/applicationlog.service';
import { LocaleService } from '../../../shared/services/locale/locale.service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { Applicationlog } from '../../../shared/models/applicationlog.model'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-applicationlog',
  templateUrl: './new-applicationlog.component.html',
  styleUrls: ['./new-applicationlog.component.css']
})
export class NewApplicationlogComponent implements OnInit, OnDestroy {
  applicationlogIsAuthenticated = false;
  private authStatusSub: Subscription;

  // Id for polling
  timeoutId: any;

  public id: any;
  public buttonName: any;
  public cancelButton: boolean;
  public applicationlog: Applicationlog = new Applicationlog;

  constructor(public route: ActivatedRoute, public router: Router, private authService: AuthService, private applicationlogService: ApplicationlogService, public dialog: MatDialog, public translate: TranslateService, public localeService: LocaleService) { }

  ngOnInit() {
    this.applicationlogIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.applicationlogIsAuthenticated = isAuthenticated;
    });

    this.id = this.route.snapshot.queryParams['Id'];
    if (this.id) {
      this.applicationlogService.getApplicationlogById(this.id).subscribe(data => {
        let apiApplicationlogData = data.applicationlog[0];
        this.applicationlog = apiApplicationlogData;
      });
      this.buttonName = "Update Applicationlog";
      this.cancelButton = true;
    } else {
      this.buttonName = "Submit Applicationlog";
      this.cancelButton = false;
    }
  }

  SubmitApplicationlog(form: NgForm) {
    if (form.invalid) {
      return;
    }

    let dialogRef;
    if (this.buttonName == "Submit Applicationlog") {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { ApplicationlogName: this.applicationlog.applicationId },
        disableClose: true,
        autoFocus: true,
        width: '250px',
      });
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { ApplicationlogName: this.applicationlog.applicationId },
        disableClose: true,
        autoFocus: true,
        width: '250px',
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.buttonName == "Submit Applicationlog") {
          this.applicationlogService.createApplicationlog(this.applicationlog).subscribe(res => {
            this.router.navigate(['admin/applicationlog-list']);
          });
        } else {
          this.applicationlogService.updateApplicationlogName(this.id, this.applicationlog).subscribe(res => {
            this.router.navigate(['admin/applicationlog-list']);
          });
        }
      }
    });
  }

  cancel() {
    this.router.navigate(['admin/applicationlog-list']);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
  }

}
