import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-config',
  templateUrl: './confirm-config.component.html',
  styleUrls: ['./confirm-config.component.css']
})
export class ConfirmConfigComponent implements OnInit {

  labelPlatform: string;
  labelField: string;
  labelObservedMoment: string;

  constructor(public dialogRef: MatDialogRef<ConfirmConfigComponent>, @Inject(MAT_DIALOG_DATA) public data: {helpToolCode: string, platform: string, field: string, observedMoment: string},public translate: TranslateService) {
    this.labelPlatform = data.platform;
    this.labelField = data.field;
    this.labelObservedMoment = data.observedMoment;
  }

  ngOnInit() {

    this.translate.get('Plattform').subscribe( (response: any)=> {
      switch (this.labelPlatform){
        case "OP": this.labelPlatform = response.OP;
          break;
        case "Bettenstation": this.labelPlatform = response.Bettenstation;
          break;
        case "Notfall": this.labelPlatform = response.Notfall;
          break;
        case "Ambulatorium": this.labelPlatform = response.Ambulatorium;
          break;
        case "Radiologie": this.labelPlatform = response.Radiologie;
          break;
      }
    });

    this.translate.get('Fachbereich').subscribe( (response: any)=> {
      switch (this.labelField){
        case "Angiologie": this.labelField = response.Angiologie;
          break;
        case "Chirurgie": this.labelField = response.Chirurgie;
          break;
        case "Endokrinologie": this.labelField = response.Endokrinologie;
          break;
        case "Gastroentologie": this.labelField = response.Gastroentologie;
          break;
        case "Hämatologie": this.labelField = response.Hämatologie;
          break;
        case "Infektiologie": this.labelField = response.Infektiologie;
          break;
        case "Innere Medizin": this.labelField = response.InnereMedizin;
          break;
        case "Interdisziplinär": this.labelField = response.Interdisziplinär;
          break;
        case "Kardiologie": this.labelField = response.Kardiologie;
          break;
        case "Nephrologie": this.labelField = response.Nephrologie;
          break;
        case "Neurologie": this.labelField = response.Neurologie;
          break;
        case "Rehabilitation": this.labelField = response.Rehabilitation;
          break;
      }
    });

    this.translate.get('Zeitpunkt').subscribe( (response: any)=> {
      switch (this.labelObservedMoment){
        case "Frühschicht": this.labelObservedMoment = response.Frühschicht;
          break;
        case "Spätschicht": this.labelObservedMoment = response.Spätschicht;
          break;
        case "Nachtschicht": this.labelObservedMoment = response.Nachtschicht;
          break;
        case "Visite": this.labelObservedMoment = response.Visite;
          break;
        case "Schichtübergabe": this.labelObservedMoment = response.Schichtübergabe;
          break;
      }
    });

  }

}
