import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Authdata } from "../../models/auth-data.model";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';

const BACKEND_URL = environment.apiUrl + "/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  downloadUserCsv() {
    return this.http.get(BACKEND_URL + "/downloadUserCsv")
  }

  createUser(user) {
    const authData: Authdata = user;
    return this.http.post(BACKEND_URL + "/signup", authData)
  }

  updateUserName(id: string, obj: object) {
    return this.http.put(BACKEND_URL + "/updateUserNameById/" + id, obj)
  }

  updateUserPassword(id: string, obj: object) {
    this.http.put(BACKEND_URL + "/updateUserPasswordById/" + id, obj).subscribe(response => {
      
    });
  }

  getUserListData() {
    return this.http.get<any>(BACKEND_URL + "/userList");
  }

  getUserByCityData(city: string) {
    return this.http.get<any>(BACKEND_URL + "/userByCity/" + city);
  }

  getUserByRegionData(region: string) {
    return this.http.get<any>(BACKEND_URL + "/userByRegion/" + region);
  }

  getUserById(id: string) {
    return this.http.get<any>(BACKEND_URL + "/userById/" + id);
  }

  deleteUserById(id: string) {
    return this.http.delete<any>(BACKEND_URL + "/userById/" + id);
  }
}
