import { RegionService } from '../../../shared/services/region/region.service';
import { LocaleService } from '../../../shared/services/locale/locale.service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogComponent } from '../../../dialogs/upload-dialog/upload-dialog.component';
import { ImageDialogComponent } from '../../../dialogs/image-dialog/image-dialog.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { Region } from '../../../shared/models/region.model'
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user/user.service';
@Component({
  selector: 'app-new-region',
  templateUrl: './new-region.component.html',
  styleUrls: ['./new-region.component.css']
})
export class NewRegionComponent implements OnInit, OnDestroy {
  regionIsAuthenticated = false;
  private authStatusSub: Subscription;

  // Id for polling
  timeoutId: any;

  public id: any;
  public buttonName: any;
  public cancelButton: boolean;
  public region: Region = new Region;
  isAdmin: boolean = true;
  constructor(public route: ActivatedRoute, public router: Router, private authService: AuthService, private regionService: RegionService, public dialog: MatDialog, public translate: TranslateService, public localeService: LocaleService, public userService: UserService) { }

  ngOnInit() {
    this.regionIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.regionIsAuthenticated = isAuthenticated;
    });
    if (localStorage.getItem('Admin') == "investigater") {
      this.isAdmin = false;
    }
    this.id = this.route.snapshot.queryParams['Id'];
    if (this.id) {
      this.regionService.getRegionById(this.id).subscribe(data => {
        let apiRegionData = data.region[0];
        this.region = apiRegionData;
      });
      this.buttonName = "Update Region";
      this.cancelButton = true;
    } else {
      this.buttonName = "Submit Region";
      this.cancelButton = false;
    }
  }

  SubmitRegion(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.buttonName == "Submit Region") {
      this.regionService.createRegion(this.region).subscribe(res => {
        this.router.navigate(['admin/region-list']);
      });
    } else {
      this.regionService.updateRegionName(this.id, this.region).subscribe(res => {
        this.router.navigate(['admin/region-list']);
      });
    }
  }

  cancel() {
    this.router.navigate(['admin/region-list']);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
  }

}
