import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  translationKey: any;
  dataLabels: any;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    this.translationKey = this.data.translationKey;
    delete this.data['translationKey'];
    this.dataLabels = Object.getOwnPropertyNames(data);
  }

  ngOnInit() {
  }

  getInstanceValue(instance: any) {
    return this.data[instance];
  }

}
