import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-start',
  templateUrl: './confirm-start.component.html',
  styleUrls: ['./confirm-start.component.css']
})
export class ConfirmStartComponent implements OnInit {

  labelObservation: string;

  constructor(public dialogRef: MatDialogRef<ConfirmStartComponent>, @Inject(MAT_DIALOG_DATA) public data: {helpToolCode: string, observation: string, roleId: string}, public translate: TranslateService) {
    this.labelObservation = data.observation;
  }

  ngOnInit() {
    this.translate.get('DefaultScreen').subscribe( (response: any)=> {
      switch (this.labelObservation){
        case "Activity Protocol Doctor": this.labelObservation = response.AktivitätenProtokollArzt;
          break;
        case "Activity Protocol Nursing Assistant": this.labelObservation = response.AktivitätenProtokollFaGe;
          break;
        case "Activity Protocol Nurse": this.labelObservation = response.AktivitätenProtokollPflege;
          break;
        case "Activity Protocol Therapist": this.labelObservation = response.AktivitätenProtokollTherapeut;
          break;
      }
    });
  }

}
