import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Authdata } from "../../models/auth-data.model";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';

const BACKEND_URL = environment.apiUrl + "/region";

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  createRegion(region) {
    return this.http.post(BACKEND_URL + "/createRegion", region)
  }

  updateRegionName(id: string, obj: object) {
    return this.http.put(BACKEND_URL + "/updateRegionById/" + id, obj)
  }

  getRegionListData(id) {
    return this.http.get<any>(BACKEND_URL + "/regionList/" + id);
  }

  getRegionById(id: string) {
    return this.http.get<any>(BACKEND_URL + "/regionById/" + id);
  }

  deleteRegionById(id: string) {
    return this.http.delete<any>(BACKEND_URL + "/deleteRegionById/" + id);
  }

}
