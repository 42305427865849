import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './shared/services/auth/auth.service';
import { LoaderService } from './shared/services/loader/loaderservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading: boolean = false;

  constructor(private authService: AuthService, public loaderService: LoaderService, private cdr: ChangeDetectorRef){}

  ngOnInit(){
    this.authService.autoAuthUser();
    this.loaderService.isLoading.subscribe(value => {
      if (value) {
        this.isLoading = value;
        this.cdr.detectChanges();
      } else {
        setTimeout(() => {
          this.isLoading = value;
          this.cdr.detectChanges();
        }, 1000);
      }

    })
  }

}
