export class StatusType {
    status = ["New", "Investigation", "Review", "Rejected", "Approved", "Executed" , "Closed"]
}
export enum NewStatus{
    "New", "Start Investigation", "Investigation Complete", "Reject", "Approved", "Executed" , "Closed"
}
export class Category {
    type = ["Cash", "Grossory (Small)" , "Grossory (Large)"]
}
export class Image {
    types = ["Application", "Record", "Investigation", "Review"]
}

export class City {
    names = ["Lahore", "Multan", "Karachi", "Islamabad"]
}

export enum NewRole {
    "admin","region admin", "investigater", "reporter"
}

export class Role {
    types = ["admin", "investigater", "reporter"]
}