import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  // check if user is logged in before routing to protected page, if not, route to login screen
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    let auth = route.routeConfig.path;
    const isAuth = this.authService.getIsAuth();
    if (!isAuth) {
      this.router.navigate(['login']);
    }

    let SuperAdminRoutesArray = ["user-list", "addActivity", "administration", "addRole", "rolesList", "addPlatform", "platformsList", "addField", "fieldsList", "addObservedMoment", "observerMomentsList", "usersList"];
    // if (SuperAdminRoutesArray.includes(auth)) {
    if (SuperAdminRoutesArray.indexOf(auth) !== -1) {

      let superAdmin = localStorage.getItem('Admin');
      if (superAdmin == "investigater") {
        this.authService.logout();
        this.router.navigate(['login']);
      }
    }
    return isAuth;
  }
}
