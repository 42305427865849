import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Authdata } from "../../models/auth-data.model";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';

const BACKEND_URL = environment.apiUrl + "/paymenttype";

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  createPaymentType(paymenttype) {
    return this.http.post(BACKEND_URL + "/createPaymentType", paymenttype)
  }

  updatePaymentTypeName(id: string, obj: object) {
    return this.http.put(BACKEND_URL + "/updatePaymentTypeById/" + id, obj)
  }

  getPaymentTypeListData(id) {
    return this.http.get<any>(BACKEND_URL + "/paymenttypeList/" + id);
  }

  getPaymentTypeById(id: string) {
    return this.http.get<any>(BACKEND_URL + "/paymenttypeById/" + id);
  }

  deletePaymentTypeById(id: string) {
    return this.http.delete<any>(BACKEND_URL + "/deletePaymentTypeById/" + id);
  }

}
