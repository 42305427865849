import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Image } from "../../shared/enums/enum";
@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css']
})
export class UploadDialogComponent implements OnInit {

  images = [];
  imageTypes = new Image();
  type = "Application";
  constructor(public dialogRef: MatDialogRef<UploadDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data,public translate: TranslateService) {
    this.type = data.status;
  }

  ngOnInit() {
    
  }
  onOkClick(): void {
    this.dialogRef.close(this.images);
  }
  onSelectFile(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader: any = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.images.push({ img: event.target.result, type: this.type });
      }
    }
  }
}
