
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ConfirmFinishComponent } from './dialogs/confirm-finish/confirm-finish.component';
import { ConfirmStartComponent } from './dialogs/confirm-start/confirm-start.component';
import { ConfirmSignupComponent } from './dialogs/confirm-signup/confirm-signup.component';
import { LoginComponent } from './login/login.component';
import { FinishDialogComponent } from './dialogs/finish-dialog/finish-dialog.component';
import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { ImageDialogComponent } from './dialogs/image-dialog/image-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { ConfirmConfigComponent } from './dialogs/confirm-config/confirm-config.component';
import { StoredConfigComponent } from './dialogs/stored-config/stored-config.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { HomeComponent } from './home/home.component';
import { NgRedux, NgReduxModule } from 'ng2-redux';
import rootReducer from './store/reducers';
import { HttpClientModule} from '@angular/common/http';

//modules
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './admin/admin.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    ConfirmFinishComponent,
    ConfirmStartComponent,
    ConfirmSignupComponent,
    LoginComponent,
    FinishDialogComponent,
    UploadDialogComponent,
    ImageDialogComponent,
    ErrorDialogComponent,
    ConfirmConfigComponent,
    StoredConfigComponent,
    ConfirmationDialogComponent,
    HomeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    NgReduxModule,
    AdminModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [ ConfirmFinishComponent, ConfirmStartComponent, ConfirmSignupComponent, FinishDialogComponent,ImageDialogComponent,UploadDialogComponent,
    ErrorDialogComponent, ConfirmConfigComponent, StoredConfigComponent, ConfirmationDialogComponent],
  exports: [SharedModule]
})

export class AppModule {
  constructor(ngRedux: NgRedux<AppState>) {
    ngRedux.configureStore(rootReducer, initialState);
  }
}

export interface AppState {
  activitiesArray: any[];
}

export const initialState: any = {};
