import { Router, Route } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../shared/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../shared/services/language/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  userName: string;
  private authStatusSub: Subscription;
  public language: any;
  public SelectedLang: any;

  constructor(private authService: AuthService, public translate: TranslateService, public languageService: LanguageService, public router: Router) {
    translate.setDefaultLang('en');
    this.SelectedLang = this.translate.currentLang;
  }

  ngOnInit() {
    this.userName = this.authService.getUserName();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });
    this.language = [{ value: 'fr', Label: "French" }, { value: 'en', Label: "English" }, { value: 'de', Label: "German" }]
  }

  onLogout() {
    this.authService.logout();
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  /*switchLanguage(language: string){
    this.translate.use(language);

    this.translate.get('EvaluationScreen').subscribe( (response: any)=> {
      this.languageResponse = response;
      this.languageUpdated.next([...this.languageResponse]);
      });
  }

  getLanguageUpdateListener() {
    return this.languageUpdated.asObservable();
  }*/
  switchLanguage(language: string) {
    this.languageService.switchLanguage(language);
  }


  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }

}
