import { CityService } from '../../../shared/services/city/city.service';
import { LocaleService } from '../../../shared/services/locale/locale.service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogComponent } from '../../../dialogs/upload-dialog/upload-dialog.component';
import { ImageDialogComponent } from '../../../dialogs/image-dialog/image-dialog.component';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { City } from '../../../shared/models/city.model'
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import { RegionService } from 'src/app/shared/services/region/region.service';
@Component({
  selector: 'app-new-city',
  templateUrl: './new-city.component.html',
  styleUrls: ['./new-city.component.css']
})
export class NewCityComponent implements OnInit, OnDestroy {
  cityIsAuthenticated = false;
  private authStatusSub: Subscription;

  // Id for polling
  timeoutId: any;

  public id: any;
  public buttonName: any;
  public cancelButton: boolean;
  public city: City = new City;
  region = [];
  isAdmin: boolean = true;
  constructor(public route: ActivatedRoute, public router: Router, private authService: AuthService, private cityService: CityService, public dialog: MatDialog, public translate: TranslateService, public localeService: LocaleService, public userService: UserService, private regionService: RegionService) { }

  ngOnInit() {
    this.cityIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.cityIsAuthenticated = isAuthenticated;
    });
    if (localStorage.getItem('Admin') == "investigater") {
      this.isAdmin = false;
    }
    this.regionService.getRegionListData(sessionStorage.getItem("userId")).subscribe(data => {
      this.region = data.region;
    });
    this.id = this.route.snapshot.queryParams['Id'];
    if (this.id) {
      this.cityService.getCityById(this.id).subscribe(data => {
        let apiCityData = data.city[0];
        this.city = apiCityData;
      });
      this.buttonName = "Update City";
      this.cancelButton = true;
    } else {
      this.buttonName = "Submit City";
      this.cancelButton = false;
    }
  }

  SubmitCity(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.buttonName == "Submit City") {
      this.cityService.createCity(this.city).subscribe(res => {
        this.router.navigate(['admin/city-list']);
      });
    } else {
      this.cityService.updateCityName(this.id, this.city).subscribe(res => {
        this.router.navigate(['admin/city-list']);
      });
    }
  }

  cancel() {
    this.router.navigate(['admin/city-list']);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.timeoutId);
  }

}
