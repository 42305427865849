import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Authdata } from "../../models/auth-data.model";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmSignupComponent } from "../../../dialogs/confirm-signup/confirm-signup.component";

import { environment } from '../../../../environments/environment';

const BACKEND_URL = environment.apiUrl + "/city";

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    this.dialog.open(ConfirmSignupComponent, dialogConfig);
  }

  createCity(city) {
    return this.http.post(BACKEND_URL + "/createCity", city)
  }

  updateCityName(id: string, obj: object) {
    return this.http.put(BACKEND_URL + "/updateCityById/" + id, obj)
  }

  getCityListData(id) {
    return this.http.get<any>(BACKEND_URL + "/cityList/" + id);
  }

  getCityById(id: string) {
    return this.http.get<any>(BACKEND_URL + "/cityById/" + id);
  }

  deleteCityById(id: string) {
    return this.http.delete<any>(BACKEND_URL + "/deleteCityById/" + id);
  }

}
