import { Router, Route } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../shared/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../shared/services/language/language.service';
import { NewRole } from '../shared/enums/enum';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit ,OnDestroy {
  userIsAuthenticated = false;
  userName: string;
  isAdmin :boolean = true;
  private authStatusSub: Subscription;
  public language: any;
  public SelectedLang: any;
  regionAdmin: boolean;

  constructor(private authService: AuthService, public translate: TranslateService, public languageService: LanguageService, public router: Router) {
    translate.setDefaultLang('en');
    this.SelectedLang = this.translate.currentLang;
  }

  ngOnInit() {
    if (localStorage.getItem('Admin') == JSON.stringify(NewRole[NewRole[2]]) || localStorage.getItem('Admin') == JSON.stringify(NewRole[NewRole[1]])){
      this.isAdmin=false;
      if(localStorage.getItem('Admin') == JSON.stringify(NewRole[NewRole[1]])){
        this.regionAdmin = true;
      }
    }
    this.userName = this.authService.getUserName();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });
    }

  onLogout() {
    this.authService.logout();
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }
  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
