import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/shared/services/application/application.service';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css']
})
export class ImageDialogComponent implements OnInit {

  image;
  id;
  constructor(public dialogRef: MatDialogRef<ImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, private applicationService: ApplicationService) {
    this.id = data.id;
  }

  ngOnInit() {
    this.applicationService.getImageById(this.id).subscribe(data => {
      this.image = "data:image/png;base64," + btoa([].reduce.call(new Uint8Array(data.image.data), function (p, c) { return p + String.fromCharCode(c) }, ''))
    });
  }

}
